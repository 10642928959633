import {
  TabContentContainer, Main,
  ReportCard,
  CardContainer,
  DomainImage,
  AnalyzeSpending,
  AnalyzeButton,
  AnalyzeLabel,
} from "./TabContent.styled";
import axios from "axios";
import { useEffect } from "react";
import Strings from "../../constants/Strings";
import zomatoImage from "../../assets/zomato.png";
import swiggyImage from "../../assets/swiggy.png";
import useFooddyStore from "../../store";
import TotalAmount from "./TotalAmount";
import MostOrderedDishes from "./MostOrderedDishes";
import SubscribeMe from "./SubscribeMe";
import Leaderboard from "./Leaderboard";

function TabContent() {
  const {
    totalAmount,
    isZomatoActivated,
    fooddyId,
    setDishesList,
    dishesList,
  } = useFooddyStore();
  const handleRedirect = () => {
    window.location.href = Strings.extensionUrl;
  };
  
  
  return (
    <TabContentContainer>
      <Main>
        <ReportCard>
          <CardContainer>
            <DomainImage>
              <img
                src={isZomatoActivated ? zomatoImage : swiggyImage}
                alt="domain-img"
              />
            </DomainImage>
            <TotalAmount />
            {totalAmount && dishesList && dishesList.length ? (
              <MostOrderedDishes />
            ) : null}
          </CardContainer>
          <SubscribeMe />
        </ReportCard>
        <Leaderboard />
      </Main>

      <AnalyzeSpending>
        <AnalyzeLabel>{Strings.analyzeLabel}</AnalyzeLabel>
        <AnalyzeButton onClick={handleRedirect}>
          {Strings.buttons.analyze}
        </AnalyzeButton>
      </AnalyzeSpending>
    </TabContentContainer>
  );
}

export default TabContent;
