import Header from "../Header/Header";
import { MainContainer } from "./Main.styled";
import BodyHeader from "../Body/BodyHeader";
import TabsHeader from "../Body/TabsHeader";
import TabContent from "../Body/TabContent";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFooddyStore from "../../store";
import Loader from "../Body/Loader";
import backgroundImage from "..//../pictures/background.png";
import InsideContainer from "../InsideContainer/InsideContainer";


const Main = () => {
  const {
    setFooddyId,
    setSwiggyActivated,
    isShareClicked,
    setShareClicked,
    fooddyId,
    setDishesList,
    isZomatoActivated,
    setTotalAmount,
    totalAmount,
    userId,
    setUserId,
    swiggyActive,
    setSwiggyActive,
    setUserName, setMonthlyAmt, setRank, setGender, setUserPrflPic, userPrflPic,  setLeaderId, setNoUser, setUserFetch, userFetched
  } = useFooddyStore();

 
  const params = useParams();
  useEffect(() => {
    setFooddyId(params.foodyid);
    setUserId(params.userid)
    if (params.platform === "swiggy") {
      setSwiggyActivated();
      setSwiggyActive(true);
    } else {
      setSwiggyActive(false);
    }
  }, []);
  
 


 

  // const fetchShareData = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BASEURL}/dashboard/${swiggyActive ? "swiggy" : "zomato"
  //       }/shareprofile`, {
  //       userId: params.userid
  //     });
  //     if (response?.data?.userRankData) {
  //       setUserName(response.data.userRankData.username)
  //       setMonthlyAmt(response.data.userRankData.month_order_amount)
  //       setRank(response.data.userRankData.order_amount_rank)
  //       setGender(response.data.userRankData.gender)
  //       if (!userFetched) {
  //         if (response.data.userRankData.gender == "female") {
  //           setUserPrflPic(getRandomWoman())
  //         } else {
  //           setUserPrflPic(getRandomMan())
  //         }
  //         setUserFetch(true)
  //       }

  //     }
  //     //  else {
  //     //   console.log(response.data?.error)
  //     //   setTotalAmount(response.data?.error);
  //     //   setDishesList([])
  //     // }
  //   } catch (error) {
  //   }
  // };

 

  

  const handleSharePopup = () => {
    if (isShareClicked) setShareClicked(false);
  };

  // return (
  //   <MainContainer className="flex-row-center">
  //     <SkeletonLoader />
  //   </MainContainer>
  // );
  
  
  return (
    
    <MainContainer onClick={handleSharePopup} backgroundImage={backgroundImage}>
      {/* <Header />
      <BodyHeader />
      <TabsHeader />
      <TabContent /> */}
      <InsideContainer />
    </MainContainer>
  );
};

export default Main;
