import { create } from "zustand";
import man1icon from "../../src/assets/menEmoji/man1.png";
const useFooddyStore = create((set, get) => ({
  baseUrl: process.env.REACT_APP_BASEURL,
  isZomatoActivated: true,
  setZomatoActivated: () =>
    set(() => ({ isZomatoActivated: true, isSwiggyActivated: false })),
  isSwiggyActivated: false,
  setSwiggyActivated: () =>
    set(() => ({ isSwiggyActivated: true, isZomatoActivated: false })),
  fooddyId: null,
  setFooddyId: (value) => set(() => ({ fooddyId: value })),
  userId: null,
  setUserId: (value) => set(() => ({ userId: value })),
  totalAmount: null,
  setTotalAmount: (value) => set(() => ({ totalAmount: value })),
  fetching: false,
  fetchingError: null,
  dishesList: null,
  setDishesList: (value) => set(() => ({ dishesList: value })),
  isShareClicked: false,
  setShareClicked: (value) => set(() => ({ isShareClicked: value })),
  rankList: null,
  setRankList: (value) => set(() => ({ rankList: value })),
  showShare: false,
  setShowShare: (value) => set(() => ({ showShare: value })),
  swiggyActive: true,
  setSwiggyActive: (value) => set(() => ({ swiggyActive: value })),
  userName: null,
  setUserName: (value) => set(() => ({ userName: value })),
  monthlyAmt: 0,
  setMonthlyAmt: (value) => set(() => ({ monthlyAmt: value })),
  rank: null,
  setRank: (value) => set(() => ({ rank: value })),
  gender: null,
  setGender: (value) => set(() => ({ gender: value })),
  userPrflPic: man1icon,
  setUserPrflPic: (value) => set(() => ({ userPrflPic: value })),
  leaderData: null,
  setLeaderData: (value) => set(() => ({ leaderData: value })),
  leaderId: null,
  setLeaderId: (value) => set(() => ({ leaderId: value })),
  noUser : false,
  setNoUser: (value)=> set(()=>({noUser: value})),
  userFetched:false,
  setUserFetch: (value)=> set(()=>({userFetched: value})),
  shareBtnImgStat:true,
  setShareBtnImgStat: (value)=>set (()=>({shareBtnImg: value})),
  amountLoader:true,
  setAmountLoader: (value)=>set (()=>({amountLoader: value})),
  profileLoader:true,
  setProfileLoader: (value)=>set (()=>({profileLoader: value})),
  leaderboardLoader:true,
  setLeaderboardLoader: (value)=>set (()=>({leaderboardLoader: value}))

}));

export default useFooddyStore;
