import { RatingContainer, RatingInnerBox, RatingBackground, RatingEmojiContainer, EmojiBox, RatingCloseBtn } from "./Rating.styled"
import PreservingEmojiIcon from "../../pictures/preservingEmoji.svg"
import FrowningEmojiIcon from "../../pictures/frowningEmoji.svg"
import NeutralEmojiIcon from "../../pictures/neutralEmoji.svg"
import SmilingEmojiIcon from "../../pictures/smilingEmoji.svg"
import GrinningEmojiIcon from "../../pictures/grinningEmoji.svg"
import RatingCloseIcon from "../../pictures/ratingClose.svg"

const Rating = (props) => {
    const closeRating=()=>{
        props.ratingCloseFn(false)

    }
    return (
        <RatingContainer>
            <RatingBackground />
            {/* </RatingBackground> */}

            <RatingInnerBox>
                <div className="ratingText">
                    Rate us
                </div>
                <div className="ratingDesc">
                    Your rating would help us make our extension even better. Thanks!
                </div>
                <RatingEmojiContainer>
                    <a href="https://chrome.google.com/webstore/detail/spending-calculator-for-s/dbbbhmnphepimpameepigkpjjnlpmjeg/reviews" target="_blank">
                        <EmojiBox emojiIcon={PreservingEmojiIcon} />
                    </a>
                    <a href="https://chrome.google.com/webstore/detail/spending-calculator-for-s/dbbbhmnphepimpameepigkpjjnlpmjeg/reviews" target="_blank">
                        <EmojiBox emojiIcon={FrowningEmojiIcon} />
                    </a>
                    <a href="https://chrome.google.com/webstore/detail/spending-calculator-for-s/dbbbhmnphepimpameepigkpjjnlpmjeg/reviews" target="_blank">
                        <EmojiBox emojiIcon={NeutralEmojiIcon} />
                    </a>
                    <a href="https://chrome.google.com/webstore/detail/spending-calculator-for-s/dbbbhmnphepimpameepigkpjjnlpmjeg/reviews" target="_blank">
                        <EmojiBox emojiIcon={SmilingEmojiIcon} />
                    </a>
                    <a href="https://chrome.google.com/webstore/detail/spending-calculator-for-s/dbbbhmnphepimpameepigkpjjnlpmjeg/reviews" target="_blank">
                        <EmojiBox emojiIcon={GrinningEmojiIcon} />
                    </a>

                </RatingEmojiContainer>

                
                <RatingCloseBtn onClick={closeRating}>
                    <img  src={RatingCloseIcon} />

                </RatingCloseBtn>

            </RatingInnerBox>
        </RatingContainer>
    )
}
export default Rating