import { ChallengeBox, ChallengeBoxDetails } from "./ChallengeContainer.styled"
import fruitbackground from '../..//pictures/fruitBackground.svg'
import medal from '../../pictures/medal.svg'
import model2Icon from "../../pictures/model2.png"
import model3Icon from "../../pictures/model3.png"
import axios from "axios";
import shareArrowIcon from '../../pictures/shareArrow.svg'
import shareArrowLightIcon from '../..//pictures/shareArrowLight.svg'
import { useState } from "react"
import useFooddyStore from "../../store"
import { useEffect } from "react"

import ChallengeLoader from "../SkeletonLoader/ChallengeLoader"

const ChallengeContainer = () => {
    const [shareBtnImg, setShareBtnImg] = useState(true)
    const [swiggyUserCount,setSwiggyUserCount] = useState(10000)
    const [zomatoUserCount,setZomatoUserCount] = useState(10000)

    const { setShowShare, showShare, totalAmount, shareBtnImgStat, setShareBtnImgStat, profileLoader } = useFooddyStore();

    const {
        setFooddyId,
        fooddyId,
        userName,
        monthlyAmt,
        rank, noUser,
        swiggyActive
    } = useFooddyStore();

    const DashBoard = () => {
        setShowShare(true)
        setShareBtnImgStat(true)
    }

    const fetchTotalCount = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASEURL}/dashboard/totalusers`
            )

            if (response?.data) {
                setZomatoUserCount(response.data.zomatoUsers)
                setSwiggyUserCount(response.data.swiggyUsers)
            }


        } catch (e) {
            console.log("Error in fetching leaderbord data", e)
        }

    }

    useEffect(()=>{
        fetchTotalCount()
    },[])


    return (
        <ChallengeBox>
            <div className="challengeHeader">Challenge your Friends</div>
            {
                profileLoader ?
                    <ChallengeLoader />
                    :
                    <ChallengeBoxDetails fruitbackground={fruitbackground} >
                        <div className="PositionWrapper">
                            <div className="positionBox">
                                <div className="userNm">
                                    {userName ? userName + "’s" : ""} Spending
                                </div>
                                <div className="awardWrapper">
                                    <img src={medal} />
                                    {
                                        !noUser &&
                                        <>

                                            {rank}{rank == 1 ? "st" : rank == 2 ? "nd" : rank == 3 ? "rd" : "th"} Position
                                        </>
                                    }


                                </div>

                            </div>

                            <div className="userRankDiv">
                                <img src={model2Icon} />
                                <img src={model3Icon} />
                                <div className="totalUser">
                                    {Math.floor(swiggyActive ?  (swiggyUserCount/1000) : (zomatoUserCount/1000))}K
                                </div>
                            </div>

                        </div>

                        <div className="monthWrapper">

                            {
                                !noUser ?
                                    <div>
                                        <div>Amount</div>
                                        <div className="monthlyAmt">₹ {monthlyAmt}</div>
                                    </div>
                                    :
                                    <div>
                                        Not yet analyzed
                                    </div>
                            }

                            {
                                !noUser &&
                                <div className="shareDiv" onMouseEnter={() => setShareBtnImg(false)} onMouseOut={() => setShareBtnImg(true)}>
                                    <img src={shareBtnImg ? shareArrowIcon : shareArrowLightIcon} onMouseEnter={() => setShareBtnImg(false)} onMouseOut={() => setShareBtnImg(true)} onClick={DashBoard} />

                                </div>
                            }


                        </div>


                    </ChallengeBoxDetails>
            }

        </ChallengeBox>
    )
}

export default ChallengeContainer