import styled from "styled-components";

export const LeftContainerWrapper = styled.div`
width: 100px;
height: 789px;
flex-shrink: 0;
border-radius: 40px 0px 0px 40px;
display: flex;
justify-content: center;
    align-items: flex-start;
    background-color: #fff;

`
export const FoodTrayImg = styled.img`
margin-top: 51px;
cursor: pointer;

&:hover{
    animation: shake 1s forwards;
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px); /* Adjust the distance and timing of the shake as needed */
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px); /* Adjust the distance and timing of the shake as needed */
  }
}
/* width: 10px;
height: 10px;
background-color: green; */

`