import styled from "styled-components";

export const BrandCardContainer = styled.div`
width: 315px;
height: 200px;
flex-shrink: 0;
border-radius: 20px;


background: ${props =>
        (props.cardState.brandName === "swiggy" && props.cardState.swiggyState) ? "#F26724" :
            (props.cardState.brandName === "zomato" && !props.cardState.swiggyState) ? "#F26724" :
                "none"};
color:  ${props =>
        (props.cardState.brandName === "swiggy" && props.cardState.swiggyState) ? "#FFF" :
            (props.cardState.brandName === "zomato" && !props.cardState.swiggyState) ? "#FFF" :
                "#606060"};
padding: 25px 20px 18px 20px;
box-sizing: border-box;
cursor: pointer;

.brandLogoDivWrapper{
    display: flex;
}

.infoDiv{
    width: 20px;
height: 20px;
flex-shrink: 0;
background-image: url(${props => props.infoIcon});
margin-left: 10px;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
}
&:hover{
    background: ${props =>
        (props.cardState.brandName === "swiggy" && props.cardState.swiggyState) ? "#F26724" :
            (props.cardState.brandName === "zomato" && !props.cardState.swiggyState) ? "#F26724" :
                "#F0E9FF"};
    transition: linear 0.6ms;
}
`
export const BrandLogoWrapper = styled.img`

`
export const BrandDescText = styled.div`
/* color: #F3F3F3; */
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 138.462% */
letter-spacing: -0.26px;
margin-top: 7px;
`

export const ExpenseText = styled.div`
/* color: #FFF; */
text-align: right;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 28px;
`

export const ExpenseAmt = styled.div`
/* color: #FFF; */
text-align: right;
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
    height: 33px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

`

export const InfoSecDiv= styled.div`
width: 200px;
height: 275px;
flex-shrink: 0;
border-radius: 4px;
background: #343434;
position: absolute;
left: 36px;
top: -4px;
padding: 17px 18px 0px 14px;
box-sizing: border-box;

&::before{
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-color: #343434;
    position: absolute;
    left: -7px;
    top: 6px;
    rotate: 46deg
}

.infoHeader{
    color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.28px;

}
ol{
    margin-left: 11px;
    padding-left: 0px;
}
 li{
    margin-top: 10px;
    color: #FFF;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
`