import { RightContainerWrapper, UserInfDiv, UserImg, PromoImgDiv, LeaderBoardTextDiv, LeaderBoardContainer, LeaderboardHeader, AllLeaderBoardheader, MonthlyLeaderBoardheader, LeaderBoardHeaderImg, ImageWrapper } from "./RightContainer.styled"
import UserPic from "../../pictures/userImg.png"
import PromoImg from "../..//pictures/promo.png"
import swiggyPromo from "../../pictures/swiggy_promo.png"
import leaderBoardIcon from "../..//pictures/leaderBoardIcon.png"
import LeaderBoardList from "../LeaderBoardList/LeaderBoardList"
import useFooddyStore from "../../store"
// import PromoImg2 from "../../pictures/promo2.png"
import { LeaderListDiv, SlnoDiv, LeaderImgDiv, Leaderprice } from "../LeaderBoardList/LeaderBoardList.styled"
import UserInfoLoader from "../SkeletonLoader/UserInfoLoader"
import axios from "axios";
import { useParams } from "react-router-dom";

import { useState } from "react"
import starIcon from '../../pictures/star.png'

import LeaderBoardLoader from "../SkeletonLoader/LeaderBoardLoader"

import man1Icon from "../../assets/menEmoji/man1.png"
import man2Icon from "../../assets/menEmoji/man2.png"
import man3Icon from "../../assets/menEmoji/man3.png"
import man4Icon from "../../assets/menEmoji/man4.png"
import man5Icon from "../../assets/menEmoji/man5.png"
import man6Icon from "../../assets/menEmoji/man6.png"
import man7Icon from "../../assets/menEmoji/man7.png"
import man8Icon from "../../assets/menEmoji/man8.png"

// import im from '../../assets/menEmoji/man1.png'

import woman1Icon from "../../assets/womenEmoji/woman1.png"
import woman2Icon from "../../assets/womenEmoji/woman2.png"
import woman3Icon from "../../assets/womenEmoji/woman3.png"
import woman4Icon from "../../assets/womenEmoji/woman4.png"
import woman5Icon from "../../assets/womenEmoji/woman5.png"
import woman6Icon from "../../assets/womenEmoji/woman6.png"
import woman7Icon from "../../assets/womenEmoji/woman7.png"
import woman8Icon from "../../assets/womenEmoji/woman8.png"
import woman9Icon from "../../assets/womenEmoji/woman9.png"
import woman10Icon from "../../assets/womenEmoji/woman10.png"
import woman11Icon from "../../assets/womenEmoji/woman11.png"
import woman12Icon from "../../assets/womenEmoji/woman12.png"
import { useEffect } from "react"

const RightContainer = () => {

    const params = useParams();


    const [isMonthLeader, setMonthLeader] = useState(true)
    const [selectedData, setSelectedData] = useState([])

    const {
        profileLoader,
        userName,
        swiggyActive, userPrflPic, leaderData, leaderId, setLeaderData, leaderboardLoader, setLeaderboardLoader, setLeaderId
    } = useFooddyStore();

    const allLeader = () => {
        setMonthLeader(false)
        setSelectedData(leaderData?.allLeaderboardData)
    }
    const monthlyLeader = () => {
        setMonthLeader(true)
        setSelectedData(leaderData?.monthLeaderboardData)

    }

    const getRandomColor = () => {
        const colors = ['#BEF0C6', '#FFC7D6', '#DBD1FC', '#FFD9A2'];
        return colors[Math.floor(Math.random() * colors.length)];
    }

    const getRandomMan = () => {
        const imgArr = [man1Icon, man2Icon, man3Icon, man4Icon, man5Icon, man6Icon, man7Icon, man8Icon];
        const randomManImg = imgArr[Math.floor(Math.random() * imgArr.length)];
        return randomManImg;
    }

    const getRandomWoman = () => {
        const imgArr = [woman1Icon, woman2Icon, woman3Icon, woman4Icon, woman5Icon, woman6Icon, woman7Icon, woman8Icon, woman9Icon, woman10Icon, woman11Icon, woman12Icon];
        const randomWomanImg = imgArr[Math.floor(Math.random() * imgArr.length)];
        return randomWomanImg;
    }




    const fetchLeaderBoardData = async () => {
        try {
            setLeaderboardLoader(true)
            const response = await axios.post(
                `${process.env.REACT_APP_BASEURL}/dashboard/${swiggyActive ? "swiggy" : "zomato"
                }/leaderboardapi`,
                {
                    userId: params.userid,
                }
            )

            if (response?.data) {
                setLeaderData(response.data.combinedLeaderboard)
                setLeaderboardLoader(false)
                setSelectedData(response.data.combinedLeaderboard?.monthLeaderboardData)
                setLeaderId(response.data.userId)

            }


        } catch (e) {
            console.log("Error in fetching leaderbord data", e)
        }



    }





    useEffect(() => {
        fetchLeaderBoardData()
    }, [swiggyActive])

    return (

        <RightContainerWrapper>

            {
                profileLoader ?
                    <UserInfoLoader />
                    :
                    <UserInfDiv>
                        {userName}
                        <ImageWrapper>
                            <UserImg src={userPrflPic} alt="Profile pictures" />
                        </ImageWrapper>
                    </UserInfDiv>

            }



            <PromoImgDiv src={PromoImg} />
            <LeaderBoardTextDiv>Leaderboard</LeaderBoardTextDiv>

            {
                leaderboardLoader ?
                    <LeaderBoardLoader />
                    :
                    <LeaderBoardContainer>
                        <LeaderboardHeader>
                            <AllLeaderBoardheader onClick={allLeader} bgColor={isMonthLeader} >
                                <LeaderBoardHeaderImg src={leaderBoardIcon} />
                                All

                            </AllLeaderBoardheader>
                            <MonthlyLeaderBoardheader onClick={monthlyLeader} bgColor={isMonthLeader}>
                                <LeaderBoardHeaderImg src={leaderBoardIcon} />
                                This Month
                            </MonthlyLeaderBoardheader>

                        </LeaderboardHeader>


                        {leaderData &&
                            // {isMonthLeader && }

                            (selectedData.map((e, index) => {
                                if (index < 5) {
                                    return (
                                        <LeaderListDiv key={index} style={{ width: leaderId == e.id ? "344px" : "315px" }}>
                                            <div style={{ display: "flex", alignItems: "center", color: "#000000" }}>
                                                <SlnoDiv style={{ backgroundImage: starIcon }}>
                                                    {e.orderAmountRank}
                                                </SlnoDiv>
                                                <ImageWrapper >
                                                    <LeaderImgDiv src={leaderId == e.id ? userPrflPic : e.gender == "female" ? getRandomWoman() : getRandomMan()} />

                                                </ImageWrapper>
                                                {e?.username}
                                            </div>

                                            <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                                                <Leaderprice style={{ color: leaderId == e.id ? "#F26724" : "#6C6C6C" }}>
                                                    ₹ {e.totalOrderAmount}
                                                </Leaderprice></div>

                                        </LeaderListDiv>
                                    )
                                }



                            }))

                        }



                    </LeaderBoardContainer>
            }




        </RightContainerWrapper>
    )
}

export default RightContainer  