import styled from "styled-components";


export const LeaderListDiv = styled.div`
width: 315px;
height: 56px;
flex-shrink: 0;
background: #FFF;
box-shadow: 0px 1px 0px 0px #E8E8E8;
margin-top: 5px;
display: flex;
    align-items: center;
    justify-content: space-between;
    color: #F26724;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 21px;
    
`

export const SlnoDiv = styled.div`
width: 32px;
height: 32px;
/* background-color: red; */
margin-left: 5px;
display: flex;
justify-content: center;
align-items: center;

`

export const LeaderImgDiv= styled.img`
margin-left: 10px;
margin-right: 11px;
height: 42px;

`
export const Leaderprice= styled.div`
color: #6C6C6C;
`