import styled from "styled-components";


export const InsideContainerwrapper = styled.div`
width: 1214px;
height: 789px;
border-radius: 40px;
background: #F9F6FF;
background: ${props => props.showShare ? "#fff" : "#F9F6FF"};
display: flex;
position: relative;
`

export const RatingBtnDiv = styled.div`
width: 39px;
    height: 102px;
    flex-shrink: 0;
    border-radius: 10px 0px 0px 10px;
    background: rgb(255, 193, 7);
    position: absolute;
    bottom: 64px;
    color: rgb(255, 255, 255);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    writing-mode: vertical-rl;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    rotate: 180deg;
    cursor: pointer;
    transition: all 0.2s;
    &:hover{
        width: 50px;
    }
`


export const SubscribeBtnDiv = styled.div`
width: 39px;
    height: 102px;
    flex-shrink: 0;
    border-radius: 10px 0px 0px 10px;
    background: #CCB3FF;
    position: absolute;
    bottom: 175px;
    color: rgb(255, 255, 255);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    writing-mode: vertical-rl;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    rotate: 180deg;
    cursor: pointer;
    transition: all 0.2s;
    &:hover{
        width: 50px;
    }
`
export const ShareLeftContainer = styled.div`
width: 100px;
height: 789px;
flex-shrink: 0;
border-radius: 40px 0px 0px 40px;
display: flex;
justify-content: center;
align-items: flex-start;
background-color: #fff;
/* background-color: red; */
`

export const GoBackDiv = styled.div`
display: flex;
width: 40px;
height: 40px;
padding: 8px 6.999px 7px 8.001px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 400px;
border: 1px solid #000;
box-sizing: border-box;
margin-top: 57px;
cursor: pointer;

&:hover{
    background: #F26724;
    border: none
}
`

export const GoBackImg = styled.img`
    rotate: 226deg;
`