import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import {
  MiddleContainerWrapper,
  MiddleContainerHeader,
  MiddleContainerDesc,
  BrandcardContainer,
  LowerCardsContainer,
} from "./MiddleContainer.styled";
import BrandCard from "../BrandCard/Brandcard";
import BrandCardLoader from "../SkeletonLoader/BrandCardLoader";
import BiggestOrderContainer from "../BiggestOrderContainer/BiggestOrderContainer";
import ChallengeContainer from "../ChallengeContainer/ChallengeContainer";
import swiggyLight from "../../pictures/swiggyLight.svg";
import swiggyDark from "../../pictures/swiggyDark.svg";
import zomatoLight from "../..//pictures/zomatoLight.svg";
import zomatoDark from "../../pictures/zomatoDark.svg";
import { useState } from "react";
import useFooddyStore from "../../store";
const MiddleContainer = () => {

  const params = useParams();



  const {
    setFooddyId,
    setSwiggyActivated,
    isShareClicked,
    setShareClicked,
    fooddyId,
    setDishesList,
    isZomatoActivated,
    setTotalAmount,
    totalAmount,
    userId,
    setUserId,
    swiggyActive,
    setSwiggyActive,
    noUser,
    setUserName, setMonthlyAmt, setRank, setGender, setUserPrflPic, userPrflPic, setLeaderId, setNoUser, setUserFetch, userFetched, setAmountLoader, amountLoader
  } = useFooddyStore();

  const fetchLocalData = async () => {
    try {
      setAmountLoader(true)
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/dashboard/${swiggyActive ? "swiggy" : "zomato"
        }/restaurant`, {
        userId: params.userid
      });
      setAmountLoader(false)
      if (response?.data?.totalAmountSpent) {
        setTotalAmount(response.data.totalAmountSpent.toString());
        setDishesList(response.data.topOrderedRestaurants);
        setNoUser(false);
      } else {
        setTotalAmount(response.data?.error);
        setNoUser(true);
        setDishesList([]);
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    if (userId) {
      fetchLocalData();
    }
  }, [userId, swiggyActive]);


  const getActiveStats = (e) => {
    if (e != swiggyActive) {
      setSwiggyActive(e);
    }
  };

  return (
    <MiddleContainerWrapper>
      <MiddleContainerHeader>FOODDY</MiddleContainerHeader>
      <MiddleContainerDesc>
        <span>A good meal </span>
        is a great way to start any day
      </MiddleContainerDesc>
      {
        amountLoader ?
          <BrandcardContainer>
            <BrandCardLoader />
            <BrandCardLoader />
          </BrandcardContainer>

          :
          <BrandcardContainer>
            <BrandCard
              brandLogo={swiggyActive ? swiggyLight : swiggyDark}
              swiggyState={swiggyActive}
              onData={getActiveStats}
              brandName="swiggy"
            />
            <BrandCard
              brandLogo={swiggyActive ? zomatoDark : zomatoLight}
              swiggyState={swiggyActive}
              onData={getActiveStats}
              brandName="zomato"
            />
          </BrandcardContainer>

      }



      <LowerCardsContainer>
        <BiggestOrderContainer />

        {<ChallengeContainer />}
      </LowerCardsContainer>
    </MiddleContainerWrapper>
  );
};

export default MiddleContainer;
