import styled from "styled-components";
import { devices } from "../../constants/devices";
export const BodyHeaderContainer = styled.div`
  width: 100%;

  display: flex;
  height: 157px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  #url-container {
    width: 299px;
    height: 52px;
  }

  #fooddy-id-container {
    width: 227px;
    height: 52px;
  }

  @media ${devices.tablet} {
    height: 97px;
    flex-direction: row;

    #url-container {
      width: 454px;
    }
  }
`;

export const IdAndShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
`;

export const ShareButton = styled.div`
  width: 123px;
  height: 52px;
  background: #f7f4ff;
  border: 0.5px solid #6a36f0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  visibility: ${(props) => (props.visibility ? "auto" : "hidden")};
  img {
    width: 20px;
    height: 22px;
  }
  #shareButton {
    font-family: poppins-normal;
    letter-spacing: 0px;
    color: #3D3D3D;
  }
  /* .lonsiN{
    right: unset !important;

  } */
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: #f7f4ff;
  border: 1px solid #70707021;
  border-radius: 4px;
  opacity: 1;

  #browser-img {
    width: 25px;
    height: 25px;
    margin-left: 13px;
  }
  #id-img {
    width: 27px;
    height: 26px;
    margin-left: 12px;
  }
  .copy-img {
    width: 18px;
    height: 17px;
    cursor: pointer;
  }
  div {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #3d3d3d;
  }
  #url {
    font-family: poppins-normal-italic;
    margin-left: 16px;
    width: 160px;
    margin-right: 46px;

    @media ${devices.tablet} {
      width: 311px;
      margin-right: 54px;
    }
  }
  #fooddy-id {
    font-family: poppins-normal;
    width: 99px;
    margin-left: 13px;
    margin-right: 43px;
  }
`;

