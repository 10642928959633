import { PubliSharedContainer, PublicShareInsideCont, PublicShareLeftContainer, PublicGoBackDiv, PublicGoBackImg } from "./PublicSharedPaje.styled"
import shareArrowIcon from '../../pictures/shareArrow.svg'
import shareArrowLightIcon from '../..//pictures/shareArrowLight.svg'

import backgroundImage from "../../pictures/background.png"
import { useState, useHistory } from "react"
import useFooddyStore from "../../store"
import { Link } from 'react-router-dom';
import UserSharePage from "../UserSharePage/UserSharePage"
// import SkeletonLoader from "../SkeletonLoader/SkeletonLoader"
import FoodTray from '../../pictures/foodTray.svg'
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react"

const PublicSharedpage = () => {

    const [publicShareBtnImg, setPublicShareBtnImg] = useState(true)

    const { setShowShare, showShare,setRank,setProfileLoader } = useFooddyStore();
    const [swiggyActive, setSwiggyActive] = useState(false)
    const [userName, setUserName]= useState(null)
    const [totAmt,setTotAmt]=useState(null)
    const [gender, setGender]=useState(null)

    const params = useParams()
    useEffect(() => {

        if (params.platform === "swiggy") {
            setSwiggyActive(true)

        } else {
            setSwiggyActive(false)

        }
        fetchShareData()
    }, [])



    const fetchShareData = async () => {
        try {
            setProfileLoader(true)
            const response = await axios.post(
                `${process.env.REACT_APP_BASEURL}/dashboard/${swiggyActive ? "swiggy" : "zomato"
                }/shareprofile`, {
                userId: params.userid
            });
            if (response?.data?.userRankData) {
                setUserName(response?.data?.userRankData?.username)
                setTotAmt(response?.data?.userRankData?.total_amount)
                setGender(response?.data?.userRankData?.gender)
                setRank(response.data.userRankData.order_amount_rank)
                setProfileLoader(false)

            }

        } catch (error) {
        }
    };


    return (
        <PubliSharedContainer backgroundImage={backgroundImage}>
            <PublicShareInsideCont>
                <PublicShareLeftContainer>

                    <PublicGoBackDiv >
                        <PublicGoBackImg src={FoodTray}
                        />
                    </PublicGoBackDiv>
                </PublicShareLeftContainer>

                <UserSharePage publicShare={true} usNm={userName} tA={totAmt} gen={gender} swAc={swiggyActive} />
            </PublicShareInsideCont>


        </PubliSharedContainer>
    )
}

export default PublicSharedpage