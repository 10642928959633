import {
  InsideContainerwrapper,
  RatingBtnDiv,
  SubscribeBtnDiv,
  ShareLeftContainer,
  GoBackDiv,
  GoBackImg,
} from "./InsideContainer.Styled";
import LeftContainer from "../LeftContainer/LeftContainer";
import MiddleContainer from "../MiddleContainer/MiddleContainer";
import RightContainer from "../RightContainer/RightContainer";
import Rating from "../Rating/Rating";
import { useState, useEffect, useMemo } from "react";
import GoBackIcon from "../../pictures/goBackArrow.svg";
import UserSharePage from "../UserSharePage/UserSharePage";
import axios from "axios";
import { useParams } from "react-router-dom";
import shareArrowIcon from "../../pictures/shareArrow.svg";
import shareArrowLightIcon from "../..//pictures/shareArrowLight.svg";
import useFooddyStore from "../../store";



import man1Icon from "../../assets/menEmoji/man1.png";
import man2Icon from "../../assets/menEmoji/man2.png";
import man3Icon from "../../assets/menEmoji/man3.png";
import man4Icon from "../../assets/menEmoji/man4.png";
import man5Icon from "../../assets/menEmoji/man5.png";
import man6Icon from "../../assets/menEmoji/man6.png";
import man7Icon from "../../assets/menEmoji/man7.png";
import man8Icon from "../../assets/menEmoji/man8.png";

// import im from '../../assets/menEmoji/man1.png'

import woman1Icon from "../../assets/womenEmoji/woman1.png";
import woman2Icon from "../../assets/womenEmoji/woman2.png";
import woman3Icon from "../../assets/womenEmoji/woman3.png";
import woman4Icon from "../../assets/womenEmoji/woman4.png";
import woman5Icon from "../../assets/womenEmoji/woman5.png";
import woman6Icon from "../../assets/womenEmoji/woman6.png";
import woman7Icon from "../../assets/womenEmoji/woman7.png";
import woman8Icon from "../../assets/womenEmoji/woman8.png";
import woman9Icon from "../../assets/womenEmoji/woman9.png";
import woman10Icon from "../../assets/womenEmoji/woman10.png";
import woman11Icon from "../../assets/womenEmoji/woman11.png";
import woman12Icon from "../../assets/womenEmoji/woman12.png";



const InsideContainer = () => {
  const [showRating, setShowrating] = useState(false);
  // const [showShare, setShowShare] = useState(true)
  const [shareBtnImg, setShareBtnImg] = useState(true);

  const { setShowShare, showShare, shareBtnImgStat, setShareBtnImgStat, setUserName, setMonthlyAmt, setRank, setGender, setUserPrflPic, swiggyActive, userPrflPic, setProfileLoader } = useFooddyStore();

  const params = useParams();


  const MemoizedChild = useMemo(() => <UserSharePage />, []);
  // const memoizedChild = useMemo(() => <ChildComponent prop1={parentProp} prop2="constantValue" />, [parentProp]);

  const showRatingFn = () => {
    setShowrating(true);
  };
  const ratingCloseFn = (val) => {
    setShowrating(val);
  };
  const closeShareFn = () => {
    setShowShare(false);
  };

  const onMouseEnterHandler = () => {
    setShareBtnImg(false);
  };



  const onMouseOutHandler = () => {
    setShareBtnImgStat(true);
  }


  const getRandomMan = () => {
    const imgArr = [
      man1Icon,
      man2Icon,
      man3Icon,
      man4Icon,
      man5Icon,
      man6Icon,
      man7Icon,
      man8Icon,
    ];
    const randomManImg = imgArr[Math.floor(Math.random() * imgArr.length)];
    return randomManImg;
  };

  const getRandomWoman = () => {
    const imgArr = [
      woman1Icon,
      woman2Icon,
      woman3Icon,
      woman4Icon,
      woman5Icon,
      woman6Icon,
      woman7Icon,
      woman8Icon,
      woman9Icon,
      woman10Icon,
      woman11Icon,
      woman12Icon,
    ];
    const randomWomanImg = imgArr[Math.floor(Math.random() * imgArr.length)];
    return randomWomanImg;
  };

  const fetchShareData = async () => {
    try {
      setProfileLoader(true)
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/dashboard/${swiggyActive ? "swiggy" : "zomato"
        }/shareprofile`,
        {
          userId: params.userid,
        }
      );
      setProfileLoader(false)

      if (response?.data?.userRankData) {
        setUserName(response.data.userRankData.username);
        setMonthlyAmt(response.data.userRankData.month_order_amount);
        setRank(response.data.userRankData.order_amount_rank);
        setGender(response.data.userRankData.gender);
        if (response.data.userRankData.gender == "female") {
          console.log("femle spot", getRandomMan());

          setUserPrflPic(getRandomWoman());
        } else {
          setUserPrflPic(getRandomMan());
          console.log("male spot");
        }
        console.log(response.data.userRankData.username, "shareprofile");

        // setTotalAmount(response.data.totalAmountSpent.toString())
        // setDishesList(response.data.topOrderedRestaurants)
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchShareData()
  }, [swiggyActive])


  return (
    <InsideContainerwrapper showShare={showShare}>
      {
        showShare ?
          <>
            <ShareLeftContainer>
              <GoBackDiv title="Dashboard">
                <GoBackImg src={shareBtnImgStat ? shareArrowIcon : shareArrowLightIcon}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseOut={onMouseOutHandler}
                  onClick={closeShareFn} />
              </GoBackDiv>

              {showRating && <Rating ratingCloseFn={ratingCloseFn} />}
            </ShareLeftContainer>
            {MemoizedChild}
          </>
          :
          <>
            <LeftContainer />
            <MiddleContainer />
            <RightContainer />


            <RatingBtnDiv onClick={showRatingFn}>
              Rate us
            </RatingBtnDiv>
            {/* <SubscribeBtnDiv>
              subscribe
            </SubscribeBtnDiv> */}

            {
              showRating &&
              <Rating ratingCloseFn={ratingCloseFn} />

            }
          </>

      }
    </InsideContainerwrapper>
  );
};

export default InsideContainer;
